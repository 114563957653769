@import url(http://weloveiconfonts.com/api/?family=entypo);
/* General */
body {
  margin: 0;
  font-family: 'NeueMontreal-Light', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f8f8;
}

#body-wrapper {
  padding-top: 90px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-text {
  font-family: 'NeueMontreal-Light', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 36px;
}

.subheader-text {
  font-family: 'NeueMontreal-Bold', 'Helvetica Neue', sans-serif;
  font-weight: 800;
  font-size: 24px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.alert-subheader{
  color: white;
  margin: 0 auto;
  text-align: center;
  background-color: #9a0400;
  border-radius: 20px;
}

.subheader-footer {
  color: white;
}

.body-text {
  font-family: 'NeueMontreal-Light', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 18px;
}

.link-text {
  font-family: 'NeueMontreal-Bold', 'Helvetica Neue', sans-serif;
  text-decoration: none;
  color: #9a0400;
}

/* Navbar */
.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  overflow: hidden;
  /*background-color: #f9f8f8;*/
  background-color: white;
  position: fixed;
  box-shadow: 0px 4px 60px -5px rgba(0,0,0,0.53);
  z-index: 9;
}

.navbar a:not(#icon-link) {
  display: inline-block;
  line-height: 2.5;
  font-family: Verdana, sans-serif;
  text-decoration: none;
	color: #9a0400;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
	position: relative;
	transition: all 0.4s ease;
  padding: 15px;
}

.navbar-static {
  position: static !important;
}
.burger-menu {
  z-index: 999999;
}
.burger-menu a {
  color: #d1d1d1 !important;
  border-left: 2px solid #9a0400;
}
.burger-menu a:hover {
  color: white !important;
  border-left: 2px solid white;
}

.burger-menu a:after {
  display: none;
}

.navbar a:not(#icon-link):after {
	content: "";
	position: absolute;
	height: 2px;
	background-color: #31081F;
	width: 0;
	left: 50%;
	bottom: 0;
  -webkit-transform: translateX(-50%);
	transform: translateX(-50%);
 transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
}

.navbar a:not(#icon-link):hover {
	color: #31081F;
}

.navbar a:not(#icon-link):hover:after {
	width: 100%;
}

#nav-image {
  display: block;
  text-align: center;
  padding: 10px;
}

#icon-link {
  display: block;
}

/* #9a0400 */

/* Footer */

.site-footer
{
  background-color:#000123;
  padding:45px 0 20px;
  font-size:15px;
  line-height:70px;
  color:#737373;
  margin-top: 36px;
  text-align: center;
}

.social {
  display: inline-block;
  margin: 0 10px;
  font-size: 24px;
  text-align: center;
  color: #bbb;
  overflow: hidden;
  transition: ease-in-out .3s;
  font-weight: bolder;
}

.social a {
  padding: 10px;
  transition: ease-in-out .3s;
  border: 1px solid #bbb;
}

.social a:hover{
  border: 1px solid white;
  color: black !important;
  background-color: white;
  cursor: pointer;
}
/* Carousel */

.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
  padding-top: 36px;
  padding-bottom: 36px;
}

.slide-item {
  text-align: center;
}


.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Portraits */

.card-image {
	display: block;
	background: #fff center center no-repeat;
	background-size: cover;
}

.card {
	display: inline-block;
	max-width: 20rem;
	margin: 1rem;
	font-size: 1rem;
	text-decoration: none;
	overflow: hidden;
	box-shadow: 0 0 3rem -1rem rgba(0,0,0,0.5);
  transition: box-shadow 0.1s, -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s, -webkit-transform 0.1s ease-in-out;
  width: 300px;
  border-radius: 8px;
}

.card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card:hover {
	-webkit-transform: translateY(-0.5rem) scale(1.0125);
	        transform: translateY(-0.5rem) scale(1.0125);
	box-shadow: 0 0.5em 3rem -1rem rgba(0,0,0,0.5);
}

.card-description {
	display: block;
	padding: 1em 0.5em;
	color: #515151;
  text-decoration: none;
  text-align: center;
}

.card-description > h2 {
	margin: 0 0 0.5em;
}

.card-description > p {
	margin: 0;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* Gradient home page */

#welcome-text{
  font-size: 72px;
  text-align: center;
  margin-top: 256px;
  color:white;
  font-weight: 900;
  font-family: 'NeueMontreal-Bold', 'Helvetica Neue', sans-serif;
}

#gradient { 
  z-index: -1;
  height: 100%;
  width: 100%;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  opacity: 1;
background: linear-gradient(67deg, #9a0400, #F71735, #FF9F1C,#256630, #296d7c, #4b1331, #2c286b, #1d5c53, #43134b,#9a0400,#931621);
background-size: 1800% 1800%;
/*ORIG-GRADIENT: background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3); */
-webkit-animation: rainbow 50s ease infinite;
-z-animation: rainbow 50s ease infinite;
  animation: rainbow 50s ease infinite;}

@-webkit-keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}
@keyframes rainbow { 
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
}

/* Lists */

.no-bullet-list {
  list-style-type: none;
}

.no-bullet-list b {
  font-family: 'NeueMontreal-Bold', 'Helvetica Neue', sans-serif;
  font-weight: 100;
}

/* Article image styling */

.image-container {
  padding: 32px 40px;
  width: 50%;
  display: block;
  margin: 0 auto;
}

.image {
  text-align: center;
  box-shadow: 0px 4px 15px 0px rgba(0,0,0,0.53);
  border-radius: 10px;
}

.image img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.image-caption {
  font-family: 'NeueMontreal-Bold', 'Helvetica Neue', sans-serif;
  text-align: center;
  background-color: white;
  border-radius: 10px;
}

/* Seminar styling */

.seminar-card {
  background-color: #000123;
  color: whitesmoke;
  display: block;
  margin-bottom: 2rem;
  margin-top: 2rem;
	font-size: 1rem;
	box-shadow: 0 0 3rem -1rem rgba(0,0,0,0.5);
  transition: box-shadow 0.1s, -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s;
  transition: transform 0.1s ease-in-out, box-shadow 0.1s, -webkit-transform 0.1s ease-in-out;
  border-radius: 8px;
}

.seminar-content{
  padding: 1.5rem;
}

iframe{
  display: block;
  margin: 0 auto;
  overflow: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

